@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* Base styles */
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

/* Hero Section */
.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
  color: var(--tecnobij-text); /* Ensure text color is set */
}

/* Footer Section */
.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

/* Call to Action */
.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)), 
              url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}

/* Default Dark Theme */
html.dark {
  --bg-color: #1a202c; /* Dark background */
  --text-color: #f7fafc; /* Light text */
  --link-color: #90cdf4; /* Link color in dark mode */
}

/* Light Theme */
html.light {
  --bg-color: #f7fafc; /* Light background */
  --text-color: #2d3748; /* Dark text */
  --link-color: #3182ce; /* Link color in light mode */
}

/* Global Styles Using Variables */
a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  color: var(--link-color); /* Ensure link hover color is set */
}

/* Navbar Styles */
.nav-links {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.nav-links button {
  background: transparent; /* No background */
  border: none; /* Remove default border */
  cursor: pointer; /* Pointer cursor on hover */
  color: var(--text-color); /* Button icon color based on theme */
  transition: color 0.3s; /* Smooth transition for color change */
}

.nav-links button:hover {
  color: var(--link-color); /* Change color on hover */
}

nav {
  height: 48px; /* Adjusted navbar height */
  border-bottom: none; /* Remove any bottom border */
  background-color: var(--bg-color); /* Match navbar with background color */
}

nav img {
  height: 32px; /* Reduced logo size */
  width: 32px; /* Reduced logo width */
}

nav h1 {
  font-size: 1.125rem; /* Reduced font size of title */
}

nav button {
  padding: 0.25rem; /* Reduced button padding */
}

nav svg {
  height: 20px; /* Smaller icon size */
  width: 20px;
}

/* Adjusted Spacing */
nav .flex {
  padding-left: 8px;
  padding-right: 8px;
}

nav .space-x-4 {
  margin-left: 0.75rem; /* Smaller spacing between nav items */
}

/* Mobile Menu Adjustments */
nav .fixed {
  top: 48px; /* Adjusted position of mobile dropdown */
}

nav .p-3 {
  padding: 0.75rem;
}

/* Main Colors */
:root {
  --tecnobij-blue: #00d4ff;
  --tecnobij-orange: #ff8c00;
  --tecnobij-dark-bg: #000000;
  --tecnobij-text: #ffffff;
}

/* Additional Custom Styles */
.hero {
  background-color: var(--tecnobij-dark-bg);
}

h1 {
  color: var(--tecnobij-orange);
}

button {
  background-color: var(--tecnobij-blue);
}

/* Utility Classes */
.text-primary {
  color: #2E4A7B; /* Primary text color */
}

.bg-primary {
  background-color: #2E4A7B; /* Use this for background elements */
}

.border-primary {
  border-color: #F0C75E; /* Border color for elements */
}

.hover\:bg-secondary:hover {
  background-color: #F0C75E; /* Hover effect for secondary background */
}
